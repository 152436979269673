<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="download">导出记录</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage } from 'element-plus'
import { campaign, campaignExport } from '@/api/activitySataStatistics'
const initData = reactive({
  formLabels: [
    // {
    //   label: 'user',
    //   title: '用户类型',
    //   placeholder: '请选择',
    //   type: 'options',
    //   options: [
    //     { value: 1, label: '顾客' },
    //     { value: 2, label: '员工' }
    //   ],
    // },
    {
      title: '统计时间',
      label: 'time',
      type: 'datePicker',
      placeholder: '请选择',
      width: '300'
    }
  ],
  fromData: {
    // 表单数据
    user: '',
    time: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'statisticsDate', label: '统计日期' },
    { prop: 'totalPv', label: '总页面访问量' },
    { prop: 'dayPv', label: '当天页面访问量' },
    { prop: 'totalSv', label: '总分享量' },
    { prop: 'daySv', label: '当天分享量' },
    { prop: 'totalActBzy', label: '白噪音活动-总页面访问量' },
    { prop: 'dayActBzy', label: '白噪音活动-当天页面访问量' },
    { prop: 'totalActLhssjq', label: '蕾虎时尚街区活动-总页面访问量' },
    { prop: 'dayActLhssjq', label: '蕾虎时尚街区活动-当天页面访问量' },
    { prop: 'totalActLjzkfj', label: '陆家嘴咖啡节活动-总页面访问量' },
    { prop: 'dayActLjzkfj', label: '陆家嘴咖啡节活动-当天页面访问量' },
    { prop: 'totalActLhbox', label: '蕾虎box活动-总页面访问量' },
    { prop: 'dayActLhbox', label: '蕾虎box活动-当天页面访问量' },
    { prop: 'totalActSunset', label: 'sunset活动-总页面访问量' },
    { prop: 'dayActSunset', label: 'sunset活动-当天页面访问量' },
    { slot: 'action' }
  ]
})

onMounted(() => {
  getDataList()
})

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    user: '',
    time: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 导出记录
const download = () => {
  const data = {}
  if (fromData.value.time !== '') {
    data.beginDate = fromData.value.time[0]
    data.endDate = fromData.value.time[1]
  }
  campaignExport(data).then(res => {
    let url = window.URL.createObjectURL(new Blob([res.data]))
    let a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', `活动数据统计记录-${dateTime()}.xls`)
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  })
}

const dateTime = time => {
  let dateday = ''
  if (time) {
    dateday = new Date(time)
  } else {
    dateday = new Date()
  }
  let year = dateday.getFullYear()
  let getMonth = dateday.getMonth() + 1
  let getDay = dateday.getDate()
  if (getMonth < 10) {
    getMonth
  }
  return `${year}-${getMonth < 10 ? '0' + getMonth : getMonth}-${
    getDay < 10 ? '0' + getDay : getDay
  }`
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.user !== '') {
    data.userType = fromData.value.user
  }
  if (fromData.value.time !== '') {
    data.beginDate = fromData.value.time[0]
    data.endDate = fromData.value.time[1]
  }
  campaign(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      res.data.records.map(item=>{
        item.statisticsDate = dateTime(item.statisticsDate)
      })
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog_input {
    width: 50%;
  }
}
.remark_box {
  align-items: flex-start;
}
</style>
