import request from '../Axios'

// 列表
export const campaign = data => {
  return request({
    url: '/page/statistics/campaign',
    method: 'post',
    data
  })
}

// 导出记录
export const campaignExport = data => {
  return request({
    url: '/page/statistics/export/campaign',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
